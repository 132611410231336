<template>
 <div class="main">
        <div class="container">
          <h1>重設密碼<router-link to="/">返回登入</router-link></h1>
          <div class="formBox">
            <div class="forgetPW">
              <p>請輸入您申請會員時使用的email信箱，我們將寄送重設密碼連結給您<span>(重設密碼連結有可能被歸類至垃圾郵件，若過一段時間後尚未收到郵件，請前往垃圾郵件查看。)</span></p>
              <div class="formRow">
                <h3>Email</h3>
                <input id="email" type="email" name="" value="" v-model="Email" required>
              </div>
              <div class="submitBtn" @click="login"><a href="javascript:void(0);">送出</a></div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
const TIME_TO_SEND = 60;

export default {
  data() {
    return {
      Email: "",
    };
  },
  mounted() {
    this.setBase("all login");
  },
  methods: {
    async load() {
    },
    async login() {
      if (!this.validate(".forgetPW")) {
        return;
      }
      try {
        var result = await this.$api.sendForgetPassword({
          Email: this.Email
        });
        alert('發送完成');
        this.$router.push('/');
      } catch (e) {
        console.error(e);
        if (e.Status == 9) {
          alert("驗證碼錯誤，請重新輸入");
        } else if (e.Status == 3) {
          alert("查無此帳號");
        } else {
          alert("登入失敗，帳號或密碼錯誤");
        }
      }
    },
  },
};
</script>